<template>
  <div class="commonBox donateBox">
    <div class="detailBox">
      <!-- 项目概况 -->
      <div class="projectDetail">
        <img :src="info.cover" class="projectCover" />
        <div class="projectBox">
          <div class="projectTitle">{{ info.name }}</div>
          <div class="projectDes">{{ info.info }}</div>
        </div>
      </div>
      <!-- 捐赠表单 -->
      <div class="donateForm">       
        <!-- 选择金额 -->
        <div class="moneyList">
          <div
            :class="
              activeMoney == item.money && item.isChoose
                ? 'moneyItem activeMoney'
                : 'moneyItem'
            "
            v-for="(item, i) in moneyList"
            :key="i"
            @click="chooseMoney(item)"
          >
            <span>{{ item.money }}</span> 元
          </div>
        </div>
        <!-- 手输金额 -->
        <div class="formItem">
          <van-field
            v-model="donateForm.money"
            label="捐赠金额"
            placeholder="请输入捐赠金额"
            class="donateMoneyInput"
            @blur="checkInMoneyList"
            type="number"
            @input="checkMoney"
            maxlength="8"
          />
        </div>
        <div class="formItem textareaClass" style="padding-top: 0;">
            <div class="itemLabel">支付方式</div>
            <van-radio-group v-model="payType" direction="horizontal" class="payBox">
              <van-radio name="1" checked-color="#b8211a" icon-size="30rem"
              ><van-icon name="wechat-pay" size="35" color="#1AAD19 "/>微信</van-radio
            >
              <van-radio name="2" v-if="info.use_alipay" checked-color="#b8211a" icon-size="30rem"
              ><van-icon name="alipay" size="35" color="#027AFF"/>支付宝</van-radio
            >
          </van-radio-group>
        </div>
        <!-- 实名匿名 -->
        <div class="ownInfo">
          <van-radio-group v-model="donateForm.ownType" direction="horizontal">
            <van-radio name="0" checked-color="#b8211a" icon-size="30rem"
              >实名捐赠</van-radio
            >
            <van-radio name="1" checked-color="#b8211a" icon-size="30rem"
              >匿名捐赠</van-radio
            >
          </van-radio-group>
        </div>
        <div v-if="donateForm.ownType == '0'">
          <div class="formItem">
            <van-field
              :required="donateForm.ownType == '0'"
              readonly
              clickable
              label="身份"
              :value="donateForm.identity"
              placeholder="选择身份"
              @click="showPicker = true"
            />
            <van-popup v-model="showPicker" round position="bottom">
              <van-picker
                show-toolbar
                :columns="indentityList"
                @cancel="showPicker = false"
                @confirm="chooseIdentity"
              />
            </van-popup>
          </div>
          <div class="formItem" v-if="addFields.name">
            <van-field
              v-model="donateForm.name"
              label="姓名(或团体)"
              placeholder="请输入姓名"
              maxlength="15"
              :required="donateForm.ownType == '0'&&requiredField.name"
            />
          </div>
          <div class="formItem sexItem" v-if="addFields.sex">
            <div :class="(donateForm.ownType == '0'&&requiredField.sex&&addFields.sex)?'itemLabel sexLabel requried':'itemLabel sexLabel'">性别</div>
            <van-radio-group v-model="donateForm.sex" direction="horizontal">
              <van-radio name="男" checked-color="#b8211a" icon-size="30rem"
                >男</van-radio
              >
              <van-radio name="女" checked-color="#b8211a" icon-size="30rem"
                >女</van-radio
              >
            </van-radio-group>
          </div>
          <div class="formItem" v-if="addFields.mobile">
            <van-field
              v-model="donateForm.mobile"
              label="手机号"
              type="tel"
              placeholder="请输入手机号"
              :required="donateForm.ownType == '0'&&addFields.mobile&&requiredField.mobile"
            />
          </div>

          <div class="formItem" v-if="addFields.graduate_year&&(donateForm.identity=='校友')">
            <van-field
              v-model="donateForm.year"
              label="入学年份"
              type="tel"
              placeholder="请输入入学年份"
              :required="donateForm.ownType == '0'&&addFields.graduate_year&&(donateForm.identity=='校友')&&requiredField.graduate_year"
            />
          </div>
          <div class="formItem" v-if="addFields.biye_year&&(donateForm.identity=='校友')">
            <van-field
              v-model="donateForm.biye_year"
              label="毕业年份"
              type="tel"
              placeholder="请输入毕业年份"
              :required="donateForm.ownType == '0'&&addFields.biye_year&&(donateForm.identity=='校友')&&requiredField.biye_year"
            />
          </div>
          <div class="formItem" v-if="addFields.graduate_class&&(donateForm.identity=='校友')">
            <van-field
              v-model="donateForm.class"
              label="毕业班级"
              placeholder="请输入毕业班级"
              :required="donateForm.ownType == '0'&&addFields.graduate_class&&(donateForm.identity=='校友')&&requiredField.graduate_class"
            />
          </div>
               <!-- 是否开发票 -->
        <div class="invoiceLine">
          <div class="itemLabel invoiceLabel" style="width:160rem;padding-left: 8rem;">是否开发票</div>
          <van-radio-group v-model="donateForm.isInvoice" direction="horizontal">
            <van-radio name="0" checked-color="#b8211a" icon-size="30rem"
              >否</van-radio
            >
            <van-radio name="1" checked-color="#b8211a" icon-size="30rem"
              >是</van-radio
            >
          </van-radio-group>
        </div>
          <div class="formItem">
            <van-field
              v-model="donateForm.IDNo"
              label="身份证号码"
              placeholder="请输入身份证号码"
              :required="requiredField.idcard||donateForm.isInvoice==1"
            />
          </div>
          <div class="formItem" v-if="addFields.work_unit">
            <van-field
              v-model="donateForm.workUnit"
              label="工作单位"
              placeholder="请输入工作单位"
              :required="donateForm.ownType == '0'&&addFields.work_unit&&requiredField.work_unit"
            />
          </div>
          <div class="formItem" v-if="addFields.jobs&&(donateForm.identity=='教职工'||donateForm.identity=='校友')">
            <van-field
              v-model="donateForm.duties"
              label="职务/职称"
              placeholder="请输入职务/职称"
              :required="donateForm.ownType == '0'&&addFields.jobs&&(donateForm.identity=='教职工'||donateForm.identity=='校友')&&requiredField.jobs"
            />
          </div>
          <div class="formItem" v-if="addFields.area&&(donateForm.identity=='其他'||donateForm.identity=='校友')">
            <van-field
              v-model="donateForm.area"
              label="所在地区"
              placeholder="请输入所在地区"
              :required="donateForm.ownType == '0'&&addFields.area&&(donateForm.identity=='其他'||donateForm.identity=='校友')&&requiredField.area"
            />
          </div>
          <div class="formItem" v-if="addFields.address&&(donateForm.identity=='家长'||donateForm.identity=='其他'||donateForm.identity=='校友')">
            <van-field
              v-model="donateForm.address"
              label="通讯地址"
              placeholder="请输入通讯地址"
              :required="donateForm.ownType == '0'&&addFields.address&&(donateForm.identity=='家长'||donateForm.identity=='其他'||donateForm.identity=='校友')&&requiredField.address"
            />
          </div>
        
          
          <div class="formItem" v-if="addFields.birthday&&(donateForm.identity=='校友'||donateForm.identity=='其他')">
            <van-field
              readonly
              clickable
              name="datetimePicker"
              :value="donateForm.date"
              label="出生年月"
              placeholder="点击选择出生年月"
              @click="showDatePicker = true"
              :required="donateForm.ownType == '0'&&addFields.birthday&&(donateForm.identity=='校友'||donateForm.identity=='其他')&&requiredField.birthday"
            />
            <van-popup v-model="showDatePicker" position="bottom">
              <van-datetime-picker
                v-model="currentDate"
                type="year-month"
                title="选择年月"
                :min-date="minDate"
                @confirm="chooseDate"
                :formatter="formatter"
                @cancel="showDatePicker = false"
              />
            </van-popup>
          </div>
          <div class="formItem" v-if="addFields.email&&(donateForm.identity=='校友'||donateForm.identity=='家长'||donateForm.identity=='其他')">
            <van-field
              v-model="donateForm.email"
              label="邮箱"
              placeholder="请输入邮箱"
              :required="donateForm.ownType == '0'&&addFields.email&&(donateForm.identity=='校友'||donateForm.identity=='家长'||donateForm.identity=='其他')&&requiredField.email"
            />
          </div>
       
          <div class="formItem textareaClass">
            <div class="itemLabel" style="padding-left: 8rem;align-items: center;justify-content: space-between;display: flex;">捐赠寄语  <img src="../assets/refresh.png" class="refreshBtn" :class="{'circle': isCircle}" @click="refresh"></div>
            <van-field
              v-model="donateForm.message"
              type="textarea"
              label=""
              label-width="0"
              placeholder="填写捐赠寄语"
              maxlength="150"
            />
          </div>
        </div>
      </div>
      <!-- 提交按钮 -->
      <div :class="isSubmit?'submitBtn submiting':'submitBtn'" @click="submitForm">{{btnText}}</div>
    </div>
  </div>
</template>

<script>
import { getProjectDonateInfo,subMitDonateForm } from "@/api/index.js";
export default {
  name: "donateTogether",
  data() {
    return {
      //刷新寄语flag
      isCircle:false,
      isWechat:1,//是否是微信
      //项目id
      projectId: "",
      // 一起捐id 
      togetherId:"",
      info: {},
      //支付方式
      payType:"1",
      donateForm: {
        money: "", //金额
        ownType: "0", //是否匿名
        name: "", //姓名(或团体)
        sex: "",
        mobile: "", //手机号
        identity: "", //身份
        year: "", //入学年份
        biye_year:"",//毕业年份
        class: "", //毕业班级
        workUnit: "", //工作单位
        duties: "", //职务职称
        area: "", //所在地区
        address: "", //通讯地址
        IDNo: "", //身份证号码
        date: "", //出生年月
        email: "", //邮箱
        message: "", //捐赠寄语
        isInvoice:"0",//是否需要发票
      },
      addFields:{},
      currentDate:new Date(2010,0,1),
      //显示年月组件
      showDatePicker: false,
      //当前所选金额
      activeMoney: "",
      //保存只有金额的列表
      moneyDate:['10','20','100','106'],
      // 改造后金额列表
      moneyList: [
        {money:'10',isChoose:false},
        {money:'20',isChoose:false},
        {money:'100',isChoose:false},
        {money:'106',isChoose:false},
      ],
      showPicker: false,
      minDate:new Date(1900, 0, 1),
      //身份列表
      indentityList: ["校友", "教职工", "家长", "其他"],
      //各身份对应的字段显示
      //校友flag （需要显示的字段有：name  sex year class IDNo date email duties area address）
      //教职工flag （需要显示的字段有：name  sex duties）
      //家长flag （需要显示的字段有：name  sex address IDNo email）
      //其他flag （需要显示的字段有：name  sex area address IDNo date email）
      //是否提交
      isSubmit:false,
      //按钮文字
      btnText:"立即支付",
      requiredField:{},//必填字段
      validator(v) {
        return /^(\d+)(\.\d{1,2})?$/.test(v.toString()); //只能输入两个小数
      },
    };
  },
  components: {},
  mounted() {
    this.isWechat = localStorage.getItem('isWechat')
    this.projectId = this.$route.query.id;
    this.togetherId = this.$route.query.together_id||0;
    // console.log(this.togetherId,"--")
    this.getDetail();
  },
  methods: {
    //获取详情
    async getDetail() {
      const res = await getProjectDonateInfo(this.projectId);
      if (res) {
        if (res.status == 200) {
          this.info = res.data;
          this.donateForm.message = this.info.tpl_message
          if(res.data.money_grade){
              this.moneyList = res.data.money_grade.map((item) => {
              return {
                money: item,
                isChoose: false,
              };
            });
          }
          this.moneyDate = res.data.money_grade
          this.requiredField = res.data.required_field
          //需要显示哪些字段
          this.addFields = res.data.add_field
        } else {
          this.$toast(res.err_msg);
        }
      }
    },
    //检查捐款金额是否在moneyList中
    checkInMoneyList(){
      if(!this.moneyDate.includes(this.donateForm.money)){
        console.log(111)
       this.activeMoney = "" 
       this.moneyList.map((item,i)=>{
        item.isChoose = false
       })
      }else{
        console.log("222")
        this.activeMoney = this.donateForm.money
        this.moneyList.map((item,i)=>{
          if(item.money==this.activeMoney){
            item.isChoose = true
          }
       })
      }
    },
    //检测捐款金额  只能输入两位小数
    checkMoney(val){
      val = val.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符
      val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数
      val = val.replace(/^\./g, ''); //首位不能输入“.”
      this.$nextTick(() => {
        this.donateForm.money = val
      })
    },
    //选择捐款钱数
    chooseMoney(item) {
      item.isChoose = !item.isChoose;
      this.moneyList.map((val) => {
        if (val.money != item.money) {
          val.isChoose = false;
        }
      });
      if (item.isChoose) {
        this.activeMoney = item.money;
        this.donateForm.money = item.money
      } else {
        this.activeMoney = "";
      }
    },
    //选择身份
    chooseIdentity(value) {
      this.donateForm.identity = value;
      this.showPicker = false;
    },
    //年月格式化
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    //年月选择
    chooseDate(date) {
      let year = date.getFullYear().toString();
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      this.donateForm.date = `${year}年${month}月`;
      this.showDatePicker = false;
    },
    //捐赠寄语刷新
    async refresh(){
      this.isCircle = true
      const res = await getProjectDonateInfo(this.projectId)
      this.isCircle = false
      if(res){
        if (res.status == 200) {
          this.donateForm.message = res.data.tpl_message
        } else {
          this.$toast(res.err_msg);
        }
      }
    },
    //提交
    submitForm() {
      if(this.isSubmit){
        return false
      }
      if (!this.donateForm.money.trim() && !this.activeMoney) {
        this.$toast("请输入或选择捐赠金额");
      } else{
        let params;
        if(this.donateForm.ownType=='0'){
          //如果是实名 校验
          if(!this.donateForm.identity){
            this.$toast('请选择身份')
          }else if(this.addFields.name&&this.requiredField.name&&!this.donateForm.name.trim()){
            this.$toast('请填写姓名、团体')
          }else if(this.addFields.sex&&this.requiredField.sex&&!this.donateForm.sex){
            this.$toast('请选择性别')
          }else if(this.addFields.mobile&&this.requiredField.mobile&&!this.donateForm.mobile.trim()){
            this.$toast('请填写手机号')
          }else if(this.addFields.graduate_year&&(this.donateForm.identity=='校友')&&this.requiredField.graduate_year&&!this.donateForm.year.trim()){
            this.$toast('请填写入学年份')
          }else if(this.addFields.biye_year&&(this.donateForm.identity=='校友')&&this.requiredField.biye_year&&!this.donateForm.biye_year.trim()){
            this.$toast('请填写毕业年份')
          }else if(this.addFields.graduate_class&&(this.donateForm.identity=='校友')&&this.requiredField.graduate_class&&!this.donateForm.class.trim()){
            this.$toast('请填写毕业班级')
          }else if(this.addFields.work_unit&&this.requiredField.work_unit&&!this.donateForm.workUnit.trim()){
            this.$toast('请填写工作单位')
          }else if(this.addFields.jobs&&(this.donateForm.identity=='教职工')&&this.requiredField.jobs&&!this.donateForm.duties.trim()){
            this.$toast('请填写职务职称')
          }else if(this.addFields.area&&(this.donateForm.identity=='其他')&&this.requiredField.area&&!this.donateForm.area.trim()){
            this.$toast('请填写所在地区')
          }else if(this.addFields.address&&(this.donateForm.identity=='家长'||this.donateForm.identity=='其他')&&this.requiredField.address&&!this.donateForm.address.trim()){
            this.$toast('请填写通讯地址')
          }else if((this.requiredField.idcard&&!this.donateForm.IDNo.trim())||(this.donateForm.isInvoice==1&&!this.donateForm.IDNo.trim())){
            this.$toast('请填写身份证号码')
          }else if(this.addFields.birthday&&(this.donateForm.identity=='校友'||this.donateForm.identity=='其他')&&this.requiredField.birthday&&!this.donateForm.date){
            this.$toast('请选择出生年月')
          }else if(this.addFields.email&&(this.donateForm.identity=='校友'||this.donateForm.identity=='家长'||this.donateForm.identity=='其他')&&this.requiredField.email&&!this.donateForm.email.trim()){
            this.$toast('请填写邮箱')
          }else{
            //如果是实名
            params = {
              item_id:this.projectId,
              together_id:this.togetherId,
              money:this.donateForm.money,
              is_anonymous:this.donateForm.ownType,
              name:this.donateForm.name,
              sex:this.donateForm.sex,
              mobile:this.donateForm.mobile,
              identity:this.donateForm.identity,
              graduate_year:this.donateForm.year,
              biye_year:this.donateForm.biye_year,
              graduate_class:this.donateForm.class,
              work_unit:this.donateForm.workUnit,
              jobs:this.donateForm.duties,
              area:this.donateForm.area,
              address:this.donateForm.address,
              id_card:this.donateForm.IDNo,
              birthday:this.donateForm.date,
              email:this.donateForm.email,
              message:this.donateForm.message,
              pay_type:this.payType==1?'wechat':'alipay',
              need_invoice:this.donateForm.isInvoice
            }
            this.isSubmit = true
            this.btnText = "提交中..."
            this.confirmForm(params)
          }
        }else if(this.donateForm.ownType == '1'){
          //如果是匿名
          params = {
            item_id:this.projectId,
            together_id:this.togetherId,
            money:this.donateForm.money,
            is_anonymous:this.donateForm.ownType,
            pay_type:this.payType==1?'wechat':'alipay'
          }
          this.isSubmit = true
          this.btnText = "提交中..."
          this.confirmForm(params)
        }
      }
    },
    //立即支付提交
    async confirmForm(params){
      const res = await subMitDonateForm(params)
      if(res.status==200){
        this.isSubmit = false
        this.btnText = "立即支付"
        //如果是支付宝支付跳alipay(判断是微信中的支付宝，还是H5中的支付宝)  如果是微信支付跳 wechatpay
        if(this.payType=='2'){
          // this.$router.push({ path:`/alipay?itemId=${this.projectId}&logId=${res.data.log_id}`})
          if(this.isWechat=='0'){
            this.$router.push({ path:`/alipay?itemId=${this.projectId}&logId=${res.data.log_id}`})
          }else{
            this.$router.push({ path:`/alipayWechat?itemId=${this.projectId}&logId=${res.data.log_id}`})
          }
        }else{
          this.$router.push({ path:`/wechatPay?itemId=${this.projectId}&logId=${res.data.log_id}&money=${this.donateForm.money}`})
        }
      }else{
        this.isSubmit = false
        this.btnText = "立即支付"
        this.$toast(res.err_msg);
      }
    }
  },
};
</script>
<style scoped lang="scss">
.donateBox {
  padding: 40rem;
  min-height: 100vh;
  height: auto;
}

.detailBox {
  width: 100%;
  min-height: 90vh;
  background-color: #fff;
  border-radius: 8rem;
  padding: 40rem 35rem;
}

.projectDetail {
  display: flex;
  margin-bottom: 55rem;

  .projectCover {
    width: 160rem;
    height: 160rem;
    margin-right: 25rem;
  }

  .projectBox {
    flex: 1;
    padding-top: 5rem;

    .projectTitle {
      width: 100%;
      font-size: 28rem;
      font-weight: 600;
      color: #121212;
      margin-bottom: 20rem;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      /*行数*/
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .projectDes {
      font-size: 20rem;
      color: #333333;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      /*行数*/
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

.donateForm {
  margin: 0 auto;
  padding: 10rem 0 20rem;
  margin-bottom: 10rem;

  .formItem {
    border-bottom: 1px solid #e7e7e7;
    padding: 20rem 0 10rem;
    margin-bottom:30rem;

    .donateMoneyInput::v-deep .van-field__label {
      border-right: 1rem solid #e7e7e7;
    }
  }

  .formItem:last-child {
    border-bottom: 0;
  }

  ::v-deep .van-field {
    padding: 20rem 60rem 20rem 0;
  }

  ::v-deep .van-field__label {
    width: 160rem;
    font-size: 26rem;
    font-weight: 700;
    color: #000;
    padding-left: 8rem;
  }

  .itemLabel {
    font-size: 26rem;
    font-weight: 700;
    color: #000;
    margin-top: 20rem;
  }

  .sexItem {
    position: relative;
    display: flex;
    align-items: center;
    padding: 35rem 0;
  }

  .sexLabel {
    margin-top: 0;
    width: 160rem;
    padding-left: 8rem;
  }
  .requried::before{
    position: absolute;
    left: -8rem;
    color: #ee0a24;
    font-size: 14px;
    content: '*';
  }
  ::v-deep .van-field__control {
    font-size: 24rem;
    color: #333333;
  }

  .textareaClass ::v-deep .van-field {
    border: 1rem solid #e7e7e7;
    padding-left: 20rem;
    margin-top: 20rem;
  }
}

::v-deep .van-cell {
  line-height: 35rem;
}

.submitBtn {
  margin: 0 auto;
  text-align: center;
  line-height: 82rem;
  border-radius: 8rem;
  color: #fff;
  font-size: 30rem;
  background-color: #b8211a;
}
.submiting{
  background-color: #666;
}
.moneyList {
  padding: 0rem 15rem 30rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .moneyItem {
    min-width: 175rem;
    line-height: 52rem;
    font-size: 20rem;
    color: #b8211a;
    text-align: center;
    border-radius: 8rem;
    border: 1px solid #b8211a;
    background-color: #fff4f4;
    margin-bottom: 18rem;
    margin-right: 18rem;
    span {
      font-size: 30rem;
      font-weight: 700;
    }
  }
  .moneyItem:nth-child(3n) {
    margin-right: 0;
  }
  .activeMoney {
    color: #fff;
    background: #b8211a;
  }
}

::v-deep .van-radio__label {
  font-size: 26rem;
}
::v-deep .van-cell--required::before{
  left: -8rem;
}
.payBox{
  margin: 30rem 0;
  ::v-deep .van-radio__label {
    display: flex;
    align-items: center;
    margin-right: 50rem;
  }
}
.invoiceLine{
  display: flex;
  align-items: center;
  margin-top: 40rem;
  margin-bottom: 20rem;
  .invoiceLabel{
    margin-top: 0;
    margin-right: 20rem;
  }
}
.refreshBtn{
  width: 35rem;
  height: 35rem;
}
.circle{
  animation: rotate 0.6s linear infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
